import React, { FC } from 'react';
import styles from './DataProtectionPage.module.scss'

export type DataProtectionPageProps = {}

export const DataProtectionPage: FC<DataProtectionPageProps> = ({}) => {
    return (
        <div className={styles.dataProtection}>
            <h1>Datenschutz&#8203;erklärung</h1>
            <h4>Datenschutz</h4>
            <p>
                Wir haben diese Datenschutzerklärung (Fassung 30.11.2020-311236326) verfasst, um Ihnen gemäß der Vorgaben der
                Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche
                Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
            </p>
            <p>
                Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung
                jedoch
                bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
            </p>
            <h4>Speicherung persönlicher Daten</h4>
            <p>
                Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse
                oder
                andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam
                mit
                dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte
                weitergegeben.
            </p>
            <p>
                Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen
                und
                für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne
                Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten
                eingesehen werden.
            </p>
            <p>
                Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung
                und
                den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu
                übermitteln.
            </p>
            <p>
                Die Rechtsgrundlage besteht nach Artikel 6 Absatz 1 a DSGVO (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die
                Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen –
                eine
                formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.
            </p>
            <h4>Rechte laut Datenschutz&#8203;grundverordnung</h4>
            <p>
                Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:
            </p>
            <ul>
                <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
                <li>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
                <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
                <li>Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener
                    Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)</li>
                <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
                <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
                <li>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden
                    Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</li>
            </ul>
            <p>
                Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen
                Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die Bundesbeauftragte für den Datenschutz und die
                Informationsfreiheit (BfDI) wenden.
            </p>
            <h4>TLS-Verschlüsselung mit https</h4>
            <p>
                Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung Artikel 25 Absatz 1
                DSGVO). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung
                im
                Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der
                Datenübertragung am kleinen Schloßsymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil
                unserer Internetadresse.
            </p>
            <p>
                Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der Firma Google Inc. Für den europäischen
                Raum
                ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
                verantwortlich.
            </p>
            <p>
                Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch
                keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains
                fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen
                getrennt
                von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre
                Google-Kontodaten,
                während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style
                Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden
                wir
                uns noch im Detail ansehen.
            </p>
            <h4>Was sind Google Fonts?</h4>
            <p>
                Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die Google seinen Nutzern kostenlos zu
                Verfügung stellt.
            </p>
            <p>
                Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz
                veröffentlicht wurden. Beides sind freie Software-Lizenzen.
                Warum verwenden wir Google Fonts auf unserer Webseite?
            </p>
            <p>
                Mit Google Fonts können wir auf unserer eigenen Webseite Schriften nutzen, doch müssen sie nicht auf unseren eigenen Server
                hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften
                sind
                automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung mit mobilen Endgeräten ein
                großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind
                Google Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern,
                Betriebssystemen
                und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch
                verzerren.
                Dank des schnellen Content Delivery Networks (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google
                Fonts
                unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den
                meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die
                Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.
            </p>
            <h4>Welche Daten werden von Google gespeichert?</h4>
            <p>
                Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf
                werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite
                besuchen.
                Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten auf das zu reduzieren,
                was
                für eine ordentliche Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und
                dient unter anderem als Datenübermittler im Softwarebereich.
            </p>
            <p>
                Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten
                Nutzungszahlen
                kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht Google auf internen
                Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um
                festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts
                veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice BigQuery, um große Datenmengen untersuchen und
                bewegen
                zu können.
            </p>
            <p>
                Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie Spracheinstellungen,
                IP-Adresse,
                Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen
                werden.
                Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.
            </p>
            <h4>Wie lange und wo werden die Daten gespeichert?</h4>
            <p>
                Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU angesiedelt
                sind.
                Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage,
                über
                die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.
            </p>
            <p>
                Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten
                grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten
                Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert
                Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.
            </p>
            <h4>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h4>
            <p>
                Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim
                Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie den Google-Support
                auf <a href="https://support.google.com/?hl=de&tid=311236326">link</a>
                kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie
                unsere Seite nicht besuchen.
            </p>
            <p>
                Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also
                unlimitiert
                auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren
                Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ
                Fragen finden Sie auf <a href="https://developers.google.com/fonts/faq?tid=311236326">link</a>. Dort geht zwar Google auf datenschutzrelevante
                schwierig, von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen.
            </p>
            <p>
                Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf <a
                href="https://www.google.com/intl/de/policies/privacy/">link</a> nachlesen.
            </p>
            <h4>Cloudflare Datenschutzerklärung</h4>
            <p>
                Wir verwenden auf dieser Webseite Cloudflare der Firma Cloudflare, Inc. (101 Townsend St., San Francisco, CA 94107, USA), um
                unsere Webseite schneller und sicherer zu machen. Dabei verwendet Cloudflare Cookies und verarbeitet User-Daten. Cloudflare,
                Inc. ist eine amerikanische Firma, die ein Content Delivery Network und diverse Sicherheitsdienste bereitstellt. Diese
                Dienste
                befinden sich zwischen dem User und unserem Hosting-Anbieter und fungieren als Reverse Proxy für Webseiten. Was das alles
                genau
                bedeutet, versuchen wir im Folgenden genauer zu erläutern.
            </p>
            <h4>Was ist Cloudflare?</h4>
            <p>
                Ein Content Delivery Network (CDN), wie es die Firma Cloudflare bereitstellt, ist nichts anderes als ein Netzwerk aus über
                das
                Internet verbundenen Servern. Cloudflare hat auf der ganzen Welt solche Server verteilt, um Webseiten schneller auf Ihren
                Bildschirm zu bringen. Ganz einfach gesagt, legt Cloudflare Kopien unserer Website an und platziert sie auf ihren eigenen
                Servern. Wenn Sie jetzt unsere Webseite besuchen, stellt ein System der Lastenverteilung sicher, dass die größten Teile
                unserer
                Webseite von jenem Server ausgeliefert werden, der Ihnen unsere Webseite am schnellsten anzeigen kann. Die Strecke der
                Datenübertragung zu Ihrem Browser wird durch ein CDN deutlich verkürzt. Somit wird Ihnen der Content unserer Webseite durch
                Cloudflare nicht nur von unserem Hosting-Server geliefert, sondern von Servern aus der ganzen Welt. Besonders hilfreich wird
                der
                Einsatz von Cloudflare für User aus dem Ausland, da hier die Seite von einem Server in der Nähe ausgeliefert werden kann.
                Neben
                dem schnellen Ausliefern von Webseiten bietet Cloudflare auch diverse Sicherheitsdienste, wie den DDoS-Schutz oder die Web
                Application Firewall an.
            </p>
            <h4>Warum wir Cloudflare auf unserer Website verwenden?</h4>
            <p>
                Natürlich wollen wir Ihnen mit unserer Webseite das bestmögliche Service bieten. Cloudflare hilft uns dabei, unsere Webseite
                schneller und sicherer zu machen. Cloudflare bietet uns sowohl Web-Optimierungen als auch Sicherheitsdienste, wie
                DDoS-Schutz
                und Web-Firewall, an. Dazu gehören auch ein Reverse-Proxy und das Content-Verteilungsnetzwerk (CDN). Cloudflare blockiert
                Bedrohungen und begrenzt missbräuchliche Bots und Crawler, die unsere Bandbreite und Serverressourcen verschwenden. Durch
                das
                Speichern unserer Webseite auf lokalen Datenzentren und das Blockieren von Spam-Software ermöglicht Cloudflare, unsere
                Bandbreitnutzung etwa um 60% zu reduzieren. Das Bereitstellen von Inhalten über ein Datenzentrum in Ihrer Nähe und einiger
                dort
                durchgeführten Web-Optimierungen reduziert die durchschnittliche Ladezeit einer Website etwa um die Hälfte. Durch die
                Einstellung „I´m Under Attack Mode“ („Ich werde angegriffen“-Modus) können laut Cloudflare weitere Angriffe abgeschwächt
                werden,
                indem eine JavaScript-Rechenaufgabe angezeigt wird, die man lösen muss, bevor ein User auf eine Webseite zugreifen kann.
                Insgesamt wird dadurch unsere Website deutlich leistungsstärker und weniger anfällig auf Spam oder andere Angriffe.
            </p>
            <h4>Welche Daten werden von Cloudflare gespeichert?</h4>
            <p>
                Cloudflare leitet im Allgemeinen nur jene Daten weiter, die von Websitebetreibern gesteuert werden. Die Inhalte werden also
                nicht von Cloudflare bestimmt, sondern immer vom Websitebetreiber selbst. Zudem erfasst Cloudflare unter Umständen bestimmte
                Informationen zur Nutzung unserer Webseite und verarbeitet Daten, die von uns versendet werden oder für die Cloudflare
                entsprechende Anweisungen erhalten hat. In den meisten Fällen erhält Cloudflare Daten wie Kontaktinformationen, IP-Adressen,
                Sicherheitsfingerabdrücke, DNS-Protokolldaten und Leistungsdaten für Webseiten, die aus der Browseraktivitäten abgeleitet
                werden. Protokolldaten helfen Cloudflare beispielsweise dabei, neue Bedrohungen zu erkennen. So kann Cloudflare einen hohen
                Sicherheitsschutz für unsere Webseite gewährleisten. Cloudflare verarbeitet diese Daten im Rahmen der Services unter
                Einhaltung
                der geltenden Gesetze. Dazu zählt natürlich auch die Datenschutzgrundverordnung (DSGVO).
            </p>
            <p>
                Aus Sicherheitsgründen verwendet Cloudflare auch ein Cookie. Das Cookie (__cfduid) wird verwendet, um einzelne User hinter
                einer
                gemeinsam genutzten IP-Adresse zu identifizieren und Sicherheitseinstellungen für jeden einzelnen User anzuwenden. Sehr
                nützlich
                wird dieses Cookie beispielweise, wenn Sie unsere Webseite aus einem Lokal benutzen, in dem sich eine Reihe infizierter
                Computer
                befinden. Wenn aber Ihr Computer vertrauenswürdig ist, können wir dies anhand des Cookies erkennen. So können Sie, trotz
                infizierter PCs im Umfeld, ungehindert durch unsere Webseite surfen. Wichtig zu wissen ist auch noch, dass dieses Cookie
                keine
                personenbezogenen Daten speichert. Dieses Cookie ist für die Cloudflare-Sicherheitsfunktionen unbedingt erforderlich und
                kann
                nicht deaktiviert werden.
            </p>
            <h4>Cookies von Cloudflare</h4>
            <p>
                __cfduid
                <ul>
                    <li>Ablaufzeit: 1 Jahr</li>
                    <li>Verwendung: Sicherheitseinstellungen für jeden einzelnen Besucher</li>
                    <li>Beispielhafter Wert: d798bf7df9&#8203;c1ad5b7583eda5cc5e783&#8203;11236326</li>
                </ul>
            </p>
            <p>
                Cloudflare arbeitet auch mit Drittanbietern zusammen. Diese dürfen personenbezogene Daten nur unter Anweisung der Firma
                Cloudflare und in Übereinstimmung mit den Datenschutzrichtlinien und anderer Vertraulichkeits- und Sicherheitsmaßnahmen
                verarbeiten. Ohne explizite Einwilligung von uns gibt Cloudflare keine personenbezogenen Daten weiter.
            </p>
            <h4>Wie lange und wo werden die Daten gespeichert?</h4>
            <p>
                Cloudflare speichert Ihre Informationen hauptsächlich in den USA und im Europäischen Wirtschaftsraum. Cloudflare kann die
                oben
                beschriebenen Informationen aus der ganzen Welt übertragen und darauf zugreifen. Im Allgemeinen speichert Cloudflare Daten
                auf
                User-Ebene für Domains in den Versionen Free, Pro und Business für weniger als 24 Stunden. Für Enterprise Domains, die
                Cloudflare Logs (früher Enterprise LogShare oder ELS) aktiviert haben, können die Daten bis zu 7 Tage gespeichert werden.
                Wenn
                allerdings IP-Adressen bei Cloudflare Sicherheitswarnungen auslösen, kann es zu Ausnahmen der oben angeführten
                Speicherungsdauer
                kommen.
            </p>
            <h4>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h4>
            <p>
                Cloudflare bewahrt Daten-Protokolle nur solange wie nötig auf und diese Daten werden auch in den meisten Fällen innerhalb
                von 24
                Stunden wieder gelöscht. Cloudflare speichert auch keine personenbezogenen Daten, wie beispielsweise Ihre IP-Adresse. Es
                gibt
                allerdings Informationen, die Cloudflare als Teil seiner permanenten Protokolle auf unbestimmte Zeit speichert, um so die
                Gesamtleistung von Cloudflare Resolver zu verbessern und etwaige Sicherheitsrisiken zu erkennen. Welche permanenten
                Protokolle
                genau speichert werden, können Sie auf <a href="https://www.cloudflare.com/application/privacypolicy/">link</a> nachlesen. Alle Daten, die
                Cloudflare sammelt (temporär oder permanent), werden von allen personenbezogenen Daten bereinigt. Alle permanenten
                Protokolle
                werden zudem von Cloudflare anonymisiert.
            </p>
            <p>
                Cloudflare geht in ihrer Datenschutzerklärung darauf ein, dass sie für die Inhalte, die sie erhalten nicht verantwortlich
                sind.
                Wenn Sie beispielsweise bei Cloudflare anfragen, ob sie Ihre Inhalte aktualisieren oder löschen können, verweist Cloudflare
                grundsätzlich auf uns als Websitebetreiber. Sie können auch die gesamte Erfassung und Verarbeitung Ihrer Daten durch
                Cloudflare
                komplett unterbinden, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren oder einen Script-Blocker in
                Ihren
                Browser einbinden.
            </p>
            <p>
                Cloudflare ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework wodurch der korrekte und sichere Datentransfer
                persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0">link</a>.
                Mehr Informationen zum Datenschutz bei Cloudflare finden Sie auf <a href="https://www.cloudflare.com/de-de/privacypolicy/">link</a>
            </p>
            <p>
                Quelle: Erstellt mit dem Datenschutz Generator von AdSimple in Kooperation mit hashtagbeauty.de
            </p>
        </div>
    )
};
