import React, { FC } from 'react';
import styles from './HomePage.module.scss';
import pic1 from '../../assets/pictures/carpic_1.jpg'
import { getCurrentVariationTexts } from "../../services/siteVariationService";

export type HomePageProps = {

}

export const HomePage: FC<HomePageProps> = ({}) => {
    const texts = getCurrentVariationTexts();

    return (
        <>
            <div className={styles.HomePage}>
                <h1 className={styles.title}>{texts.company.name}</h1>
                <img src={pic1} alt={"pic1"}/>
                <p>Unser Team bringt Sie sicher an Ihr Ziel!</p>
                <p>Ob sitzend oder liegend, im Tragestuhl oder auf der Trageliege.</p>
            </div>
        </>
    )
};
