import React, { FC, useState } from "react"
import { FormInput, FormSection } from "./inputs/FormInput";
import commonStyles from "../../assets/common.module.scss"
import styles from "./OrderForm.module.scss"
import { combine } from "../../utils/utils";
//@ts-ignore
import { Honeypot, NetlifyFormComponent, NetlifyFormProvider, useNetlifyForm } from "react-netlify-forms";
import { useForm } from "react-hook-form";
import { FormTextArea } from "./inputs/FormTextArea";
import axios from 'axios';

export type OrderFormProps = {}

export const OrderForm: FC<OrderFormProps> = ({}) => {
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [submitted, setSubmitted] = useState(false);

    const requiredMessage = "Pflichtfeld";
    const today = new Date().toISOString().split("T")[0];

    const netlify = useNetlifyForm({
        name: "order",
        honeypotName: "bot-field"
    })

    const onSubmit = (data: any) => {
        let url = "/.netlify/functions/order";
        axios.post(url, data);

        setSubmitted(true)
    };

    const formSections: FormSection[] = [
        {
            name: "Kontaktdaten",
            inputs: [
                {
                    label: "Vorname und Name",
                    type: "text",
                    register: register("credentials", {required: true, maxLength: 150}),
                    error: errors.Name && requiredMessage
                },
                {
                    label: "Telefonnummer",
                    type: "tel",
                    register: register("phone", {required: true, maxLength: 100}),
                    error: errors.Telefonnummer && requiredMessage
                },
                {
                    label: "E-Mail",
                    type: "email",
                    register: register("email", {required: true, maxLength: 200}),
                    error: errors.Email && requiredMessage
                },
                {
                    label: "Krankenkasse",
                    type: "text",
                    register: register("insurance", {required: true, maxLength: 200}),
                    error: errors.Krankenkasse && requiredMessage
                },
            ]
        },
        {
            name: "Termindaten",
            inputs: [
                {
                    label: "Datum der Abholung",
                    type: "date",
                    name: "pickupDate",
                    value: today,
                    register: register("pickupDate", {required: true, maxLength: 250}),
                    error: errors.DatumDerAbholung && requiredMessage
                },
                {
                    label: "Uhrzeit Abholung",
                    type: "text",
                    name: "pickupTime",
                    //placeholder: 'Wann soll der Patient abgeholt werden?',
                    register: register("pickupTime", {required: true, maxLength: 250}),
                    error: errors.UhrzeitAbholung && requiredMessage
                },
                {
                    label: "Uhrzeit Termin",
                    type: "text",
                    name: "appointmentTime",
                    //placeholder: 'Wann soll der Patient am Zielort sein?',
                    register: register("appointmentTime", {required: true, maxLength: 250}),
                    error: errors.UhrzeitTermin && requiredMessage
                },
            ]
        }, {
            name: "Transport von",
            inputs: [
                {
                    label: "Einrichtung",
                    placeholder: "z. B. Wohnung, Krankenhaus, etc.",
                    type: "text",
                    name: "placeFrom",
                    register: register("placeFrom", {required: true, maxLength: 250}),
                    error: errors.Einrichtung && requiredMessage
                },
                {
                    label: "Straße und Hausnummer",
                    type: "text",
                    name: "streetFrom",
                    register: register("streetFrom", {required: true, maxLength: 250}),
                    error: errors.StrasseVon && requiredMessage
                },
                {
                    label: "Postleitzahl und Ort",
                    type: "text",
                    name: "areaFrom",
                    register: register("areaFrom", {required: true, maxLength: 250}),
                    error: errors.Postleitzahl && requiredMessage
                },
            ]
        },
        {
            name: "Transport nach",
            inputs: [
                {
                    label: "Einrichtung",
                    placeholder: "z. B. Wohnung, Krankenhaus, etc.",
                    type: "text",
                    name: "placeTo",
                    register: register("placeTo", {required: true, maxLength: 250}),
                    error: errors.EinrichtungNach && requiredMessage
                },
                {
                    label: "Straße und Hausnummer",
                    type: "text",
                    name: "streetTo",
                    register: register("streetTo", {required: true, maxLength: 250}),
                    error: errors.StrasseNach && requiredMessage
                },
                {
                    label: "Postleitzahl und Ort",
                    type: "text",
                    name: "areaTo",
                    register: register("areaTo", {required: true, maxLength: 250}),
                    error: errors.PostleitzahlNach && requiredMessage
                },
            ]
        }];

    return (
        <NetlifyFormProvider {...netlify}>
            <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" name="form-name" value="order"/>
                <div className={styles.form}>
                    {formSections.map(section =>
                        <div className={styles.formSection} key={section.name}>
                            <h4 className={styles.sectionTitle}>{section.name}</h4>
                            {section.inputs.map(entry =>
                                <FormInput
                                    {...entry}
                                    key={entry.label.toString()}/>
                            )}
                        </div>)}
                    <div className={combine(styles.formSection, styles.marginBottom)}>
                        {/*<h4 className={styles.sectionTitle}>Angaben zum Transport</h4>*/}
                        <h4>Transportart: </h4>

                        <input type="radio" value="Rollstuhl" id="wheelchairOption" {...register("transportType", {required: true})}/>
                        <label htmlFor="wheelchairOption">Rollstuhl</label><br/>
                        <input type="radio" value="Tragestuhl" id="carryingChairOption" {...register("transportType", {required: true})}/>
                        <label htmlFor="carryingChairOption">Tragestuhl</label><br/>
                        <input type="radio" value="Liegend" id="lyingDownOption" {...register("transportType", {required: true})}/>
                        <label htmlFor="lyingDownOption">Liegend</label><br/>
                        {errors.Transportart && <p className={styles.errorMessage}>{requiredMessage}</p>}
                    </div>
                    <div className={combine(styles.formSection, styles.marginBottom)}>
                        <h4>Transportschein: </h4>
                        <input type="radio" value="Ja" id="yesOption" {...register("transportBill", {required: true})}/>
                        <label htmlFor="yesOption">Ja</label><br/>
                        <input type="radio" value="Nein" id="noOption" {...register("transportBill", {required: true})}/>
                        <label htmlFor="noOption">Nein</label><br/>
                        <input type="radio" value="Privat" id="privateOption" {...register("transportBill", {required: true})}/>
                        <label htmlFor="privateOption">Privat</label><br/>
                        {errors.Transportschein && <p className={styles.errorMessage}>{requiredMessage}</p>}
                    </div>
                    <div className={styles.formSection}>
                        <h4 className={styles.sectionTitle}>Zusätzliche Informationen</h4>
                        <FormTextArea className={styles.otherInfoInput} register={register("otherInfo")}/>
                    </div>
                </div>

                {submitted
                    ? <p>Vielen Dank, wir werden Ihre Anforderung in Kürze bestätigen.</p>
                    : <button className={combine(commonStyles.buttonPrimary, styles.submitButton)} type="submit">Anfordern</button>}
            </NetlifyFormComponent>
        </NetlifyFormProvider>
    )
};

//TODO translation
