import React, { FC } from 'react'
import { OrderForm } from '../forms/OrderForm';

export type OrderPageProps = {

}

export const OrderPage: FC<OrderPageProps> = ({}) => {
    return (
        <>
            <h1>Fahrdienst anfordern</h1>
            <OrderForm/>
        </>
    )
};
