import React, { FC } from 'react'
import styles from './Footer.module.scss'
import { NavLink } from 'react-router-dom';

export type FooterProps = {
}

export const Footer: FC<FooterProps> = ({}) => {
    return (
            <div className={styles.footer}>
                <NavLink to="/imprint" className={styles.link}>
                    <span className={styles.linkText}>Impressum</span>
                </NavLink>
                <NavLink to="/contact" className={styles.link}>
                    <span className={styles.linkText}>Kontakt</span>
                </NavLink>
                <NavLink to="/data-protection" className={styles.link}>
                    <span className={styles.linkText}>Datenschutzerklärung</span>
                </NavLink>
            </div>
    )
};
