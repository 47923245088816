import { GeneralTexts, textVariations } from "./texts";

export function getCurrentVariationTexts(): GeneralTexts {
    const variation = getCurrentVariation();

    const texts = textVariations.find(x => x.id === variation)
    if (!texts) { throw new Error() }
    return texts;
}

function getCurrentVariation(): WebsiteVariations {
    return WebsiteVariations.BW;
}


export enum WebsiteVariations {
    BW = "BW",
    TRL = "TRL",
    TKM = "TKM"
}
