import React, { FC } from 'react'
import { ContactInfo } from '../ContactInfo';
import { ContactForm } from '../forms/ContactForm';
import styles from './ContactPage.module.scss';

export type ContactProps = {}

export const ContactPage: FC<ContactProps> = ({}) => {
    return (
        <>
            <h1>Kontakt</h1>
            <ContactInfo className={styles.contactInfo} withCompanyPhone={false}/>
            <ContactForm/>
        </>
    )
};
