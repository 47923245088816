import React, { FC, ReactElement, Ref } from "react"
import styles from "./FormTextArea.module.scss"
import { combine } from "../../../utils/utils";

export type FormTextAreaProps = {
    label?: string | ReactElement,
    placeholder?: string,
    value?: string,
    register?: any,
    error?: string | ReactElement,
    className?: string,
}

export type FormSection = { name: string, inputs: FormTextAreaProps[] }

export const FormTextArea: FC<FormTextAreaProps> = ({label, placeholder, value, register, error, className}) => {
    const today = new Date().toISOString().split("T")[0];
    return (
        <div className={combine(styles.formEntry, className)}>
            {label && <p className={styles.label}>{label}</p>}
            <textarea placeholder={placeholder} {...register} defaultValue={value}
                      className={combine(styles.textarea, error && styles.error)}/>
            {error && <p className={styles.errorMessage}>{error}</p>}
        </div>
    )
};
