import React, { FC, ReactElement, Ref } from 'react'
import styles from './FormInput.module.scss'
import { combine } from '../../../utils/utils';

export type FormEntryProps = {
    label: string | ReactElement,
    name?:string,
    type: string,
    placeholder?: string,
    value?:string,
    register?: any,
    error?: string | ReactElement,
    className?: string,
}

export type FormSection = { name: string, inputs: FormEntryProps[] }

export const FormInput: FC<FormEntryProps> = ({label, type, placeholder, value, register, error, className}) => {
    const today = new Date().toISOString().split('T')[0];
    return (
        <div className={combine(styles.formEntry, className)}>
            <p className={styles.label}>{label}</p>
            <input type={type} placeholder={placeholder} {...register} defaultValue={value} min={today} className={combine(styles.input, error && styles.error)}/>
            {error && <p className={styles.errorMessage}>{error}</p>}
        </div>
    )
};
