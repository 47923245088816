import React, { FC, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import styles from './Header.module.scss'
import logo from '../assets/logo-short.png';
import { combine } from '../utils/utils';
import { getCurrentVariationTexts } from "../services/siteVariationService";

export type HeaderProps = {}

export const Header: FC<HeaderProps> = ({}) => {
    const [hamburgerMenuOpen, setHamburgerMenu] = useState(false);
    const menuClick = () => {
        setHamburgerMenu(!hamburgerMenuOpen);
    };

    const handleMenuClick = () => {
        setHamburgerMenu(false);
    };

    const texts = getCurrentVariationTexts();

    return (
        <>
            <div className={styles.header}>
                <Link className={styles.logoLink} to="/"><img src={logo} className={styles.logo}/></Link>
                <span className={styles.companyName}>{texts.company.name}</span>
                <button className={combine('hamburger hamburger--squeeze', styles.menuButton, hamburgerMenuOpen && 'is-active')}
                        type="button" onClick={menuClick}>
              <span className="hamburger-box">
                <span className="hamburger-inner"/>
              </span>
                </button>

                <nav className={styles.desktopNav}>
                    <NavLink to="/" className={styles.link} activeClassName={styles.active} exact={true}>
                        <span className={styles.linkText}>Home</span>
                    </NavLink>
                    <NavLink to="/contact" className={styles.link} activeClassName={styles.active}>
                        <span className={styles.linkText}>Kontakt</span>
                    </NavLink>
                    <NavLink to="/about" className={styles.link} activeClassName={styles.active}>
                        <span className={styles.linkText}>Über uns</span>
                    </NavLink>
                </nav>
            </div>

            <nav className={combine(styles.mobileNav, hamburgerMenuOpen && styles.showMobile)}>
                <NavLink to="/" className={styles.link} onClick={handleMenuClick} activeClassName={styles.active} exact={true}>
                    <span className={styles.linkText}>Home</span>
                </NavLink>
                <NavLink to="/order" className={styles.link} onClick={handleMenuClick} activeClassName={styles.active}>
                    <span className={styles.linkText}>Jetzt Anfordern</span>
                </NavLink>
                <NavLink to="/contact" className={styles.link} onClick={handleMenuClick} activeClassName={styles.active}>
                    <span className={styles.linkText}>Kontakt</span>
                </NavLink>
                <NavLink to="/about" className={styles.link} onClick={handleMenuClick} activeClassName={styles.active}>
                    <span className={styles.linkText}>Über uns</span>
                </NavLink>
            </nav>
        </>)
};
