import React, { FC } from 'react'
import styles from './Sidebar.module.scss'
import commonStyles from '../assets/common.module.scss'
import { combine } from '../utils/utils';
import { Link } from 'react-router-dom';
import { getCurrentVariationTexts } from "../services/siteVariationService";


export type SidebarProps = {}

export const Sidebar: FC<SidebarProps> = ({}) => {
    const services = ['Dialyse-Fahrten', 'Bestrahlungsfahrten', 'Reha-Fahrten', 'Entlassungs- und Verlegefahrten', 'Konsilfahrten', 'Arztfahrten'];
    const texts = getCurrentVariationTexts();

    return (
        <div className={styles.sidebar}>
            <div className={commonStyles.box}>
                <h4 className={styles.title}>Unsere Leistungen</h4>
                <div className={combine(styles.servicesWrapper)}>
                    {services.map(service =>
                        <div className={styles.service} key={service}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/>
                            </svg>
                            <span>{service}</span>
                        </div>)}
                </div>
                <Link to="/order" className={combine(commonStyles.buttonSecondary, styles.orderButton)}>JETZT ANFORDERN</Link>
            </div>
            <div className={combine(styles.contactWrapper, commonStyles.box)}>
                <p className={styles.moreQuestionsFirstLine}>Eine Frage? Ein Wunsch?</p>
                <p className={styles.moreQuestionsSecondLine}>Rufen Sie uns an</p>
                <a className={combine(commonStyles.buttonSecondary, styles.phoneButton)} href={"tel:" + texts.phone}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path
                            d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/>
                    </svg>
                    <span>{texts.phone}</span>
                </a>
                <div className={styles.workingHours}><span>{texts.workingDays}</span> <span>{texts.workingHours}</span></div>
            </div>
            <div className={combine(styles.locationsWrapper, commonStyles.box)}>
                <h4 className={styles.locationsTitle}><b>Einsatzgebiete</b></h4>
                <div className={styles.locations}>
                    {texts?.locations.map((location, id) => <p key={id}>{location}</p>)}
                </div>
                <div className={styles.surroundings}><p> + Umland</p></div>
            </div>

        </div>
    )
};
