import React, { FC } from 'react';
import styles from './ContactInfo.module.scss';
import { combine } from '../utils/utils';
import { getCurrentVariationTexts } from "../services/siteVariationService";

export type ContactInfoProps = {
    withCompanyPhone: boolean,
    className?: string
}

export const ContactInfo: FC<ContactInfoProps> = ({className, withCompanyPhone}) => {
    const texts = getCurrentVariationTexts();
    const email = texts.contact.email;
    const phone = texts.contact.phone;
    const companyPhone = texts.company.phone;

    return (
        <div className={combine(styles.contactInfo, className)}>
            <p>{texts.contact.name}</p>
            <p>{texts.contact.address}</p>
            <p className={styles.marginBottom}>{texts.contact.location}</p>
            <p>{withCompanyPhone ? "Zentraletelefon" : "Telefon"}: <a href={`tel:${phone}`}>{phone}</a></p>
            {withCompanyPhone && <p>Mobiltelefon: <a href={`tel:${companyPhone}`}>{companyPhone}</a></p>}
            <p>Email: <a className={styles.emailLink} href={`mailto:${email}`}>{email}</a></p>
        </div>
    )
};
