import React from 'react';
import styles from './App.module.scss'
import commonStyles from '../assets/common.module.scss';
import { Header } from './Header';
import { Routes } from './pages/Routes';
import { Sidebar } from './Sidebar';
import { HashRouter } from 'react-router-dom';
import { Footer } from './Footer';
import { combine } from '../utils/utils';

function App() {
    return (
        <HashRouter>
            <div className={styles.app}>
                <Header/>
                <div className={styles.coolio}>
                    <div className={combine(styles.pageContent, commonStyles.box)}>
                        <Routes/>
                    </div>
                    <div className={styles.sidebar}>
                        <Sidebar/>
                    </div>
                </div>

                <Footer/>
            </div>
        </HashRouter>
    );
}

export default App;
