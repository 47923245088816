import React, { FC, useState } from 'react';
import styles from './ContactForm.module.scss';
import { useForm } from 'react-hook-form';
import { FormInput, FormSection } from "./inputs/FormInput";
import { combine } from '../../utils/utils';
import commonStyles from '../../assets/common.module.scss';
import axios from 'axios';
import { FormTextArea } from "./inputs/FormTextArea";

export type ContactFormProps = {}

export const ContactForm: FC<ContactFormProps> = ({}) => {
    const {register, handleSubmit, formState:{errors}} = useForm();
    const [submitted, setSubmitted] = useState(false);

    const requiredMessage = 'Pflichtfeld';

    const onSubmit = (data: any) => {
        let url = "/.netlify/functions/contact";
        axios.post(url, data);
        setSubmitted(true);

        // credentials=vf&credentials=f
        // Content-Type: application/x-www-forms-urlencoded
    };

    const formSections: FormSection[] = [
        {
            name: 'Kontaktdaten',
            inputs: [
                {
                    label: 'Ihre Name',
                    type: 'text',
                    register: register("name", {required: true, maxLength: 150}),
                    error: errors.name && requiredMessage
                },
                {
                    label: 'Ihre Email-Adresse',
                    type: 'text',
                    register: register("email", {required: true, maxLength: 100}),
                    error: errors.email && requiredMessage
                },
                {
                    label: 'Betreff',
                    type: 'text',
                    register: register("subject", {required: true, maxLength: 200}),
                    error: errors.subject && requiredMessage
                },
            ]
        },];

    return (
        <form onSubmit={handleSubmit(onSubmit)} method="POST">
            <div className={styles.form}>
                {formSections.map(section =>
                    <div className={styles.formSection} key={section.name}>
                        <h4 className={styles.sectionTitle}>{section.name}</h4>
                        {section.inputs.map(entry =>
                            <FormInput
                                {...entry}
                                key={entry.label.toString()}/>
                        )}
                    </div>)}
                <div className={styles.formSection}>
                    <h4 className={styles.sectionTitle}>Ihre Nachricht</h4>
                    <FormTextArea register={register("content", {required: true})} error={errors.content && requiredMessage}/>
                </div>
            </div>

            {submitted
                ? <p>Vielen Dank für Ihre Nachricht, wir werden Sie in Kürze kontaktieren.</p>
                : <button className={combine(commonStyles.buttonPrimary, styles.submitButton)} type="submit">Senden</button>}
        </form>
    )
};
