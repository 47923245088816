import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom';
import { AboutPage } from './AboutPage';
import { ContactPage } from './ContactPage';
import { HomePage } from './HomePage';
import { OrderPage } from './OrderPage';
import ScrollToTop from '../../utils/ScrollToTop';
import { ImprintPage } from './ImprintPage';
import { DataProtectionPage } from './DataProtectionPage';

export type RoutesProps = {}

export const Routes: FC<RoutesProps> = ({}) => {
    return (<>
        <ScrollToTop />
        <Switch>
            <Route exact path="/" >
                <HomePage/>
            </Route>
            <Route path="/about">
                <AboutPage/>
            </Route>
            <Route path="/contact">
                <ContactPage/>
            </Route>
            <Route path="/order">
                <OrderPage/>
            </Route>
            <Route path="/imprint">
                <ImprintPage/>
            </Route>
            <Route path="/data-protection">
                <DataProtectionPage/>
            </Route>
        </Switch>

    </>)
};
