import React, { FC } from 'react'
import styles from './AboutPage.module.scss'
import pic2 from '../../assets/pictures/carpic_2.jpg'
import pic3 from '../../assets/pictures/carpic_3.jpg'
import pic4 from '../../assets/pictures/carpic_4.jpg'

export type AboutProps = {}

export const AboutPage: FC<AboutProps> = ({}) => {
    return (
        <div className={styles.aboutUs}>
            <h1>Über uns</h1>

            <p>Unter Krankenfahrten verstehen wir die Beförderung sowohl sitzender als auch legender Patienten die wahrend der Fahrt einer
                medizinischen Betreuung nicht bedürfen.</p>

            <h4>Unser Service</h4>

            <p>
                Pünktlichkeit und Zuverlässigkeit zeichnen uns aus Ist Ihr Patient oder Angehöriger in einer stabilen gesundheitlichen
                Verfassung, muss aber aus medizinischen Gründen trotzdem sitzend, liegend, im Tragestuhl oder im Rollstuhl transportiert
                werden, so finden Sie in uns eine zuverlässige und pünktliche Ergänzung zu den öffentlich rechtlichen Unternehmen. </p>
            <p>
                Wir fahren sehr gern auch gehfähige Patienten, die vom Krankenhaus entlassen werden, zur Dialyse, Bestrahlung. zum Arzt oder
                auch zu privaten Anlassen müssen oder auch möchten.
            </p>
            <p>
                Wir sind ebenfalls der richtige Partner für Sie, wenn Ihr in der Mobilität eingeschränkter Patient oder Angehöriger zu Hause
                in einem Obergeschoss lebt und die Wohnung tür nur über eine Treppe erreicht werden kann. Die meisten stehen hier vor einem
                Problem, das unlösbar erscheint. Nicht für uns, denn in diesem Fall setzen wir den Patienten in einen Tragestuhl um und
                transportieren ihn so aus der oder in die Wohnung.
            </p>
            <p>
                Selbstverständlich für uns ein freundlicher und zuvorkommender Umgang mit den uns anvertrauten Patienten Wir übernehmen den
                Patienten in seinen eigenen vier Wanden und übergeben ihn direkt an den Ansprechpartner in der jeweiligen Einrichtung oder
                sonstigen Institution So können Sie sich immer darauf verlassen, dass der Patient in guten Händen ist. Krankenbeförderung
                ist eine Aufgabe wie keine andere. denn hier werden Menschen, die durch ihre Krankheit oder Behinderung beeinträchtigt sind,
                transportiert. Meist sind diese Menschen nicht ohne Weiteres in der Lage sich frei von Ort zu Ort zu bewegen. Wir sehen
                unsere Aufgabe darin, diese Menschen sicher, zuverlässig pünktlich und bequem zu befördern.
            </p>
            <h4>Technisch sind wir gut ausgerüstet</h4>
            <p>
                Damit wir den Transport für unsere Kunden so sicher und angenehm we nur möglich gestalten können, bedienen wir uns bester
                und innovativer Technik. Dazu gehören nicht zuletzt unsere Fahrzeuge.
            </p>
            <p>
                Alle Fahrzeuge wurden von deutschen Fachfirmen so umgebaut das nicht nur sämtliche gesetzlichen Vorschriften für
                Krankentransporte erfüllt und die Fahrzeuge auf den neuesten technischen Stand gebracht wurden, sondern es wurde explizit
                darauf geachtet, dass ein maximaler Fahrkomfort für den Patienten erreicht wird.
            </p>

            <p className={styles.signature}>IHR KFS TEAM</p>

            <div className={styles.picContainer}>
                <img className={styles.picHigh} src={pic2} alt={'pic2'}/>
                <div className={styles.smallPicContainer}>
                    <img className={styles.pic} src={pic4} alt={'pic4'}/>
                    <img className={styles.pic} src={pic3} alt={'pic3'}/>
                </div>
            </div>
        </div>
    )
};
