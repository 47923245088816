export type GeneralTexts = {
    id: "BW" | "NW" | "KEK";
    locations: string[];
    phone: string;
    workingDays: string;
    workingHours: string;
    contact: ContactTexts;
    company: CompanyTexts;
}
export type ContactTexts = {
    name: string;
    address: string;
    location: string;
    phone: string;
    email: string;
}

export type CompanyTexts = {
    name: string;
    phone: string;
    registryType: string;
    registryNumber: string;
    registryPlace: string;
}

export const textVariations: GeneralTexts[] = [{
    id: "BW",
    locations: [
        "Mannheim",
        "Ludwigshafen",
        "Worms",
        "Frankenthal",
        "Speyer",
        "Schwetzingen",
        "Hockenheim",
    ],
    phone: "+49 (0) 7261 975 66 88",
    workingDays: "Mo-So",
    workingHours: "06:00-22:00 Uhr",
    contact: {
        name: "Krankenfahrservice BW GmbH",
        address: "Am Flugplatz 3",
        location: "67547 Worms",
        phone: "072619756688",
        email: "kontakt@kfsbw.de"
    },
    company: {
        name: "Krankenfahrservice BW",
        phone: "+4917611555533",
        registryType:"Handelsregister",
        registryNumber: "HRB 735731",
        registryPlace: "Amtsgericht Mannheim"
    }
},
    {
        id: "KEK",
        locations: [
            "Mannheim-KEK",
            "Heidelberg-KEK",
            "Sinsheim-KEK",
            "Heilbronn-KEK",
        ],
        phone: "+49 (0)1111111",
        workingDays: "-KEK-KEK",
        workingHours: "KEKEK Uhr",
        contact: {
            name: "Krankenfahrservice KEK GmbH",
            address: "Im Ottenthal 2 KEK",
            location: "74889 Sinsheim KEK",
            phone: "123123123 KEK",
            email: "kontakt@kfsbw.de KEK"
        },
        company: {
            name: "Krankenfahrservice KEK",
            phone: "+asdasdasasdasd",
            registryType:"HandelsregisterKEK",
            registryNumber: "HRB KEKKEKKEKKEKKEKKEK",
            registryPlace: "Amtsgericht MannheiKEKKEKKEKKEKKEKKEKKEKKEKKEKKEKKEKKEKKEKKEKm"
        }
    }];
